.popup-alert {
  position: fixed;

  width: 20rem;
  max-width: 80%;
  min-height: 6rem;
  background-color: white;

  top: calc(var(--navbar-base-height) + 1rem);
  right: 1rem;

  z-index: 999;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 1px solid rgba(170, 170, 170, 0.357);
  border-bottom: 0;
}

.popup-alert .text-container {
  /* background-color: blue; */
  width: 90%;
  flex: 1;

  font-size: 0.8rem;
}

.popup-alert .auto-disapear-loader {
  height: 0.4rem;
  width: 0%;
  background-color: green;
  margin-top: 0.2rem;

  align-self: flex-start !important;

  animation: popupAlertLoader 4s linear;
}

.popup-alert .alert-status-row {
  margin: 0.2rem 0;

  width: 100%;

  padding: 0 0.2rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.popup-alert .lottie-container {
  position: relative;
  height: 2rem;
  width: auto;
  aspect-ratio: 1 !important;

  /* background-color: burlywood; */
}

.popup-alert .lottie-container > svg {
  width: 100%;
  height: 100%;
}

.popup-alert .title-container {
  height: 2rem;
  flex: 1;
  /* background-color: green; */

  display: flex;
  flex-direction: row;
  align-items: center;

  padding-right: 0.2rem;

  font-weight: bold;
  font-size: 0.9rem;
}

.close-button-container {
  position: relative;

  height: 1.2rem;
  width: auto;
  aspect-ratio: 1 !important;

  align-self: start;

  display: grid;
  place-items: center;

  cursor: pointer;

  background-color: transparent;
  border: none;
  padding: 0;
}

.close-button-container > svg {
  width: 100%;
  height: 100%;
}

@keyframes popupAlertLoader {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}
