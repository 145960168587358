#homepage header.hero {
  position: relative;
  background-color: #2a2a72;
  background-image: linear-gradient(315deg, #050777 0%, #009ffd 74%);
  background-position: left !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: #fff;
  padding-bottom: 80px !important;
  display: block !important;
}

#homepage .hero-background {
  position: absolute !important;
  width: 100% !important;
  height: calc(100% - var(--navbar-base-height));
  left: 0 !important;
  background-image: url(../../assets/logo-circularbranding-white.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.05;
}

#homepage header.hero .hero-text {
  width: 100%;
  height: 100%;
  margin-top: 20%;
  margin-bottom: 20%;
}

#homepage header.hero .row {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

#homepage header.hero h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 4.5em;
  font-weight: 600;
  line-height: 1.4em;
}

#homepage header.hero .btn {
  margin: 3em 0;
  color: #050577;
  background-color: #fff;
  cursor: pointer !important;
}

#homepage header.hero ul.social-links {
  padding: 0;
  display: inline-block;
  text-align: center;

  cursor: pointer;
}

#homepage header.hero ul.social-links li:hover {
  text-decoration-line: underline;
}

#homepage header.hero ul.social-links li {
  display: inline-block;
}

#homepage header.hero ul.social-links li a {
  padding-left: 17px;
  opacity: 0.6;
}

#homepage header.hero ul.social-links li:last-child a {
  padding-left: 0px !important;
}

#homepage header.hero ul.social-links li.label {
  font-size: 20px;
  opacity: 0.6;
  font-weight: 400;
}

#homepage header.hero ul.social-links li a:hover {
  opacity: 1;
}

#homepage header.hero ul.social-links li a img,
#homepage header.hero ul.social-links li a svg {
  height: 30px !important;
  width: auto;
  aspect-ratio: 1;
}

@media (max-width: 767px) {
  #homepage header.hero {
    text-align: center;
    padding-bottom: 0;
  }

  #homepage header.hero .btn-lg {
    padding: 0.8em 3em;
    font-size: 0.8em;
  }

  #homepage header.hero h1 {
    font-size: 2em;
    margin-top: 1.5em;
  }

  #homepage header.hero h1.heading {
    font-size: 2em;
  }

  #homepage header.hero h3 {
    font-size: 1.2em;
    line-height: 1.6em;
  }

  #homepage header.hero ul.social-links li.label {
    display: block;
    font-size: 16px;
    margin-bottom: 1em;
  }

  #homepage header.hero ul.social-links li a {
    opacity: 1;
  }
}

@media (max-width: 1199px) {
  #homepage header.hero .hero-text {
    text-align: center;
  }
}
