#homepage section .card {
  --overlap: 5rem;
  margin-top: calc(-1 * var(--overlap));
  margin-bottom: calc(1 * var(--overlap));

  border-radius: 0;
}

#homepage #news-and-updates h1 {
  font-size: 3rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #4e4c58;
  padding-bottom: 3rem;
}

#homepage .sent-animation {
  width: 100% !important;
  height: 10rem !important;
}

#homepage section form {
  background-color: rgba(240, 240, 240, 1);

  background-image: url(../../assets/sky.jpeg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  padding: 1rem;
  border-radius: 0.725rem;

  max-width: 600px;

  position: relative;
}

#homepage section form > div > * {
  margin-bottom: 0.8rem;
}

#homepage section .card .card-body {
  padding: 3rem 5rem;
}

@media (max-width: 767px) {
  #homepage #news-and-updates h1 {
    font-size: 2.5rem;
  }
}

@media (max-width: 575px) {
  #homepage #news-and-updates h1 {
    font-size: 2.25rem;
  }

  #homepage section .container {
    --bs-gutter-x: 0px;
  }

  #homepage section #banner img {
    border-radius: 0;
  }

  #homepage section .card {
    --overlap: 0px;
  }

  #homepage section .card {
    border: 0;
  }

  #homepage section .card .card-body {
    padding: 1em 2rem;
  }

  #homepage #news-and-updates h1 {
    padding: 3rem 3rem;
  }
}
