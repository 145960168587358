/* src/components/Starfield.css */
.starfield-container {
  overflow: hidden;
  position: relative;
}

.starfield-canvas {
  position: absolute;
  top: 0;
  left: 0;
}
